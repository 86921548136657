import React from 'react';
import { graphql } from 'gatsby';
import camelCase from 'lodash/camelCase';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

// Components
import { EmptyTable, PlaceholderCard } from 'components';
import { TrackSeasonCards } from 'components/SeasonCards';

// Styles
import { PostFeed } from 'styles';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate } from 'templates';

// Hooks
import { getFluidImage } from 'hooks';

// Types
import { Season } from 'types';
import { isEven } from '../utils';
export interface Props {
  data: {
    [key: string]: {
      data: any; // TODO: fix `any`.
      meets: Meets[];
    };
  };
  path: string;
  pageContext: {
    isCreatedByStatefulCreatePages: boolean;
    season: Season;
  };
}

export interface Meets {
  data: MeetData;
}

export interface MeetData {
  meet_name: string;
  meet_cover_photo: any; // TODO: fix `any`
}

const TrackRecordsLandingPage = (props: Props) => {
  const { season } = props.pageContext;
  // Match the key name from GQL (indoorTrack / outdoorTrack).
  const properSeason = camelCase(season);

  // Null check.
  if (!properSeason) {
    return null;
  }

  // Decide the which fallback image to get for `All time`.
  const defaultSeason =
    properSeason === 'indoorTrack' ? 'defaultIndoor' : 'defaultOutdoor';

  const fallbackCover = getFluidImage(props.data[defaultSeason].data);

  // Lodash get array of meets based on season.
  const meetNames = props.data[properSeason].meets.map(({ data }: Meets) => ({
    meetName: data.meet_name,
    coverPhoto: data.meet_cover_photo,
  }));

  // Get all of the unique meet names.
  const uniqueMeets = uniqBy(meetNames, 'meetName');

  // Group them by MIAA and put the rest in 'allTime'.
  const { miaaMeets = [], allTimeMeets = [] } = groupBy(
    uniqueMeets,
    ({ meetName }: { meetName: string }) => {
      // Null check meetName and group as `miaaMeets`.
      if (meetName && meetName.includes('MIAA')) {
        return 'miaaMeets';
      }

      // Otherwise group as `allTimeMeets`.
      return 'allTimeMeets';
    }
  );

  const hasEvenMeetCount = isEven(uniqueMeets.length);

  const title = meetNames.length
    ? `${season} Records`
    : `No ${season} Records yet`;

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate templateTitle={title}>
          <section css={[PostFeed]}>
            {meetNames.length ? (
              <>
                <TrackSeasonCards
                  miaaMeets={miaaMeets}
                  allTimeMeets={allTimeMeets}
                  season={season}
                  fallbackCover={fallbackCover}
                  {...props}
                />
                {/* Account for `All Time`. */}
                {hasEvenMeetCount && <PlaceholderCard />}
              </>
            ) : (
              <EmptyTable />
            )}
          </section>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default TrackRecordsLandingPage;

export const query = graphql`
  query {
    defaultOutdoor: airtable(
      table: { eq: "Meet Names" }
      data: {
        meet_name: {
          eq: "Massachusetts All-Time Outdoor Track & Field Records"
        }
      }
    ) {
      data {
        attachments {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 3720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    outdoorTrack: allAirtable(
      filter: { table: { eq: "Outdoor Track And Field Records" } }
    ) {
      meets: nodes {
        data {
          meet_name
          meet_cover_photo {
            data {
              attachments {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 3720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    defaultIndoor: airtable(
      table: { eq: "Meet Names" }
      data: {
        meet_name: { eq: "Massachusetts All-Time Indoor Track & Field Records" }
      }
    ) {
      data {
        attachments {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 3720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    indoorTrack: allAirtable(
      filter: { table: { eq: "Indoor Track And Field Records" } }
    ) {
      meets: nodes {
        data {
          meet_name
          meet_cover_photo {
            data {
              attachments {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 3720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
